import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogContent,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import HotlineMessage, { FirebasePost } from "./HotlineMessage";
import { BenzieGiftsPromo } from "./BenzieGifts";
import { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "../hooks/useMediaQuery";
import { useMessages } from "../hooks/MessagesContext";
import { WordCloud } from "./WordCloud";
import { getDatabase, ref, set, push, query, orderByChild, serverTimestamp, onChildAdded, onChildRemoved, onChildChanged } from "firebase/database";
import { IFirebasePost } from "../types";
import { useSnackbar } from "notistack";
import { reverseGeo, ILocation } from "../utils";

function SimpleDialog(props: { onClose: () => void; open: boolean }) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Gratitude Circle Hotline
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            We all have some gratitude to express. Whether it be a family member, friend, teacher, doctor, nurse, your neighbor, or anyone else, you can recognize them here.
          </p>
          Hotline callers can:
          <ol>
            <li>Listen to the Gratitude Message of the Day</li>
            <li>Leave a voice recording thanking someone</li>
            <li>
              Send a text message expressing your gratitude for whatever or
              whomever you choose
            </li>
          </ol>
          <p>
            Submitted voicemails and SMS messages will be reviewed and posted
            publicly on this website. Call today!&nbsp;&nbsp;
            <a href="tel:+19793105162">+1 (979) 310 5162</a>
          </p>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

const centeredGridItems = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const MainSection = () => {
  const isMd = useMediaQuery("(min-width: 900px)");
  const isLg = useMediaQuery("(min-width: 1200px)");


  /** */
  const [webMessages, setWebMessages] = useState<Record<string, IFirebasePost>>({});
  const [webMessageKeys, setWebMessageKeys] = useState<string[]>([])
  useEffect(() => {
    const db = getDatabase();
    const recentPostsRef = query(ref(db, 'posts'), orderByChild('createdAt'));
    onChildAdded(recentPostsRef, (data) => {
      // @ts-ignore
      setWebMessageKeys((prev) => [data.key, ...prev]);
      // @ts-ignore
      setWebMessages((prev) => ({ ...prev, [data.key]: data.val(), }))
    })
    onChildRemoved(recentPostsRef, (data) => {
      setWebMessageKeys((prev) => prev.filter(k => k !== data.key))
    })
    onChildChanged(recentPostsRef, (data) => {
      // @ts-ignore
      setWebMessages((prev) => ({ ...prev, [data.key]: data.val(), }))
    })
  }, [])


  /** */
  const messages = useMessages()!.messages;
  const loadMoreMessages = useMessages()!.loadMoreMessages;
  const reachedEnd = useMessages()!.reachedEnd;
  const isLoading = useMessages()!.isLoading;
  const nextPage = useMessages()!.nextPage;

  useEffect(() => {
    if (nextPage === 1) loadMoreMessages(nextPage);
  }, [nextPage]);

  // const numColumns = isLg ? 3 : isMd ? 2 : 1;
  const numColumns = isLg ? 2 : 1;

  const messages0 = webMessageKeys.filter((m, i) => i % numColumns === 0);
  const messages1 = webMessageKeys.filter((m, i) => i % numColumns === 1);
  // const messages2 = messages.filter((m, i) => i % numColumns === 2);

  return (
    <Grid item container sm={12} lg={10} xs={12} columnSpacing={3}>
      {!!messages0.length && (
        <Grid item container lg={4} md={6} sm={12} spacing={3} sx={{ height: 'min-content' }}>
          {messages0.map((key, i) => (
            <Grid item key={webMessages[key].createdAt}>
              <FirebasePost msg={webMessages[key]} msgKey={key} />
            </Grid>
          ))}
        </Grid>
      )}
      {!!messages1.length && (
        <Grid item container lg={4} md={6} sm={12} spacing={3} sx={{ height: 'min-content' }}>
          {messages1.map((key, i) => (
            <Grid item key={webMessages[key].createdAt}>
              <FirebasePost msg={webMessages[key]} msgKey={key} />
            </Grid>
          ))}
        </Grid>
      )}

      <Grid item container lg={4} md={6} sm={12} spacing={3} sx={{ height: 'min-content' }}>
        {messages.map((msg, i) => (
          <Grid item key={msg.created}>
            <HotlineMessage msg={msg} />
          </Grid>
        ))}
      </Grid>


      {isLoading && (
        <Grid item xs={12} sx={centeredGridItems}>
          <CircularProgress />
        </Grid>
      )}
      <Grid item xs={12} sx={centeredGridItems}>
        <Button
          variant="contained"
          size="large"
          onClick={() => loadMoreMessages(nextPage)}
          disabled={reachedEnd}
        >
          {reachedEnd ? "No more messages" : "See more messages"}
        </Button>
      </Grid>
    </Grid>
  );
};

const TopSection = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [message, setMessage] = useState('');
  const [msgFrom, setMsgFrom] = useState('');
  const [msgTo, setMsgTo] = useState('');
  const [location, setLocation] = useState<ILocation | undefined>();
  const [locationRaw, setLocationRaw] = useState<GeolocationCoordinates>()
  const { enqueueSnackbar } = useSnackbar();

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (geo: GeolocationPosition) => {
        setLocationRaw(geo.coords);
        const loc = await reverseGeo(geo.coords.latitude, geo.coords.longitude);
        // @ts-ignore
        setLocation(loc);
      });
    }
  }, [])
  useEffect(() => {
    if (!location) {
      getLocation()
    }
  }, [getLocation, location])

  const handleSubmit = () => {
    if (!message) {
      return enqueueSnackbar("Message cannot be empty.", { variant: "error" });
    }

    const db = getDatabase();
    const postListRef = ref(db, 'posts');
    const newPostRef = push(postListRef);
    set(newPostRef, {
      message,
      by: msgFrom,
      to: msgTo,
      createdAt: serverTimestamp(),
      visible: true,
      likes: 0,
      location: location ? {
        city: location.city,
        state: location.state,
        country: location.country,
        latitude: locationRaw?.latitude,
        longitude: locationRaw?.longitude,
        accuracy: locationRaw?.accuracy,
      } : null,
    }).then(() => {
      enqueueSnackbar("Successfully posted message.", { variant: "success" });
      setMessage('');
      setMsgFrom('');
      setMsgTo('');
    });
  }

  return (
    <Grid item container xs={12} marginTop={5} marginBottom={3} spacing={3}>

      <Grid item container sm={12} md={8} columnSpacing={2} component={FormControl} direction={"row"}>
        <Grid item sm={12} md={7}>
          <TextField
            multiline={true}
            minRows={4}
            placeholder="I am grateful for..."
            variant="outlined"
            label="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required={true}
            fullWidth
          />
        </Grid>

        <Grid item container sm={12} md={3} rowGap={1}>

          <Grid item>
            <TextField
              variant="standard"
              placeholder="From"
              value={msgFrom}
              onChange={(e) => setMsgFrom(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              variant="standard"
              placeholder="To"
              value={msgTo}
              onChange={(e) => setMsgTo(e.target.value)}
            />
          </Grid>

          <Button variant="contained" size="large" onClick={handleSubmit} type="submit">
            Post
          </Button>
        </Grid>
      </Grid>

      <Grid item container sm={12} md={4} rowGap={2}>
        <Grid item xs={12}>

          <Typography variant="h5" component="span">
            Official line:&nbsp;
          </Typography>
          <Typography variant="h5" component="a" href="tel:+19793105162">
            +1 (979) 310 5162
          </Typography>
        </Grid>

        <Grid item container xs={12} columnGap={2} rowGap={2}>
          <Button variant="contained" size="medium" href="tel:+19793105162" sx={{ height: 'fit-content' }}>
            Call
          </Button>
          <Button variant="contained" size="medium" href="sms:+19793105162" sx={{ height: 'fit-content' }}>
            Text
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setIsDialogOpen(true)}
            sx={{ height: 'fit-content' }}
          >
            What is this?
          </Button>
          <SimpleDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          />
        </Grid>
      </Grid>

    </Grid>
  );
};

function Body() {
  return (
    <Grid container spacing={3}>
      <TopSection />
      <MainSection />
      <Grid item sm={12} lg={2}>
        <WordCloud />
        <BenzieGiftsPromo />
      </Grid>
    </Grid>
  );
}
export default Body;
